<template>
  <section id="Planos">
    <div class="container">
      <PlansCardUpdate />
    </div>
  </section>
</template>

<script>
import PlansCardUpdate from '@/components/PlansCardUpdate.vue'

export default {
  components: {
    PlansCardUpdate,
  },
}
</script>

<style scoped>
.card-body {
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-content: space-around;
  flex-wrap: wrap;
}
.card-title {
  font-weight: bold !important;
  font-size: 30px !important;
}

.plans-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  justify-content: center;
}

h2.plan-price {
  font-size: 5rem !important;
}

h2.plan-price span {
  font-size: 2rem !important;
  line-height: 2rem !important;
}

h2.plan-price span.unit {
  vertical-align: super !important;
}

h2.plan-price span.month {
  font-weight: normal !important;
  color: #999;
}

.dark-layout h2.plan-price span.month {
  color: #aaa;
}

h2 {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 55px;
  line-height: 55px;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
</style>
