import { Store } from 'vuex'

import Controller from '../../../controllers/base.controller'
import { CreditController } from '../../../controllers/credit.controller'
import { CreditEntity } from '../../../entities/credit.entity'

export default {
  namespaced: true,
  state: {
    loading: new Set(),
  },
  getters: {},
  mutations: {
    /**
     * Adds to the loading state the given credit id.
     *
     * @param {Object} state the credit state.
     * @param {string} data the credit id.
     */
    ADD_LOADING(state, data) {
      const set = new Set(state.loading)
      set.add(data)

      state.loading = set
    },

    /**
     * Removes the given credit id from the loading state.
     *
     * @param {Object} state the credit state.
     * @param {string} data the credit id.
     */
    REMOVE_LOADING(state, data) {
      const set = new Set(state.loading)
      set.delete(data)

      state.loading = set
    },
  },
  actions: {
    /**
     * Creates a credit with the given payload.
     *
     * @param {CreditEntity} payload the credit to be created.
     */
    async create(_, payload) {
      const now = new Date()

      try {
        new CreditController().create(
          new CreditEntity({
            ...payload,
            createdAt: now.toISOString(),
            updatedAt: now.toISOString(),
            date: now.getTime(),
          }),
        )
      } catch (e) {
        throw e
      }
    },

    /**
     * Fetches a credit target according to the given target.
     *
     * @param {Store} store the Vuex store.
     * @param {CreditEntity} payload the credit that contains the target ref.
     * @returns the target document data.
     */
    async fetchTarget({ commit }, payload) {
      commit('ADD_LOADING', payload.id)

      try {
        if (!payload.targetRef) {
          return null
        }

        const result = await new Controller().getById(
          payload.targetRef.split('/'),
        )

        if (!result.exists()) {
          return null
        }

        return result.data()
      } catch (e) {
        throw e
      } finally {
        commit('REMOVE_LOADING', payload.id)
      }
    },
  },
}
