<template>
  <div v-if="load">
    <b-row align-h="center" align-v="center" style="height:50vh" >
    <b-spinner ></b-spinner>
    </b-row>
  </div>
  <div v-else>
    <div v-if="reHirePlan && plans" class="col-12 col-md-10 offset-md-1 top40">
      <div class="plans-grid" v-if="plans[0]">
        <div v-for="price in plans[0].prices" :key="price.id">
          <div v-if="price.active">
            <b-card align="center" class="mb-1" style="width: 300px">
              <b-card-title bold class="card-title m-0">{{
                price.metadata.pricingName
              }}</b-card-title>

              <h2 class="plan-price top20 text-secondary">
                <span class="unit">{{ getCurrency(price.currency) }}</span
                >{{ formatPrice(price.unit_amount)
                }}<span class="month">{{
                  $t('public.planes.sectionFeatures1')
                }}</span>
              </h2>

              <b-list-group flush class="list-group-circle top20">
                <b-list-group-item class="text-left">
                  {{ price.metadata.allowedMeasurements }}
                  {{ $t('admin.plansCard.measurements') }}
                </b-list-group-item>

                <b-list-group-item class="text-left">
                  {{ price.metadata.allowedSellers }}
                  {{ $t('admin.plansCard.sellers') }}
                </b-list-group-item>

                <b-list-group-item class="text-left">
                  {{ $t('admin.plansCard.bonus') }}
                </b-list-group-item>

                <b-list-group-item
                  class="text-left"
                  v-if="price.metadata.discount != null"
                >
                  {{ price.metadata.discount
                  }}{{ $t('admin.plansCard.discount') }}
                </b-list-group-item>
              </b-list-group>

              <b-button
                class="top20"
                :disabled="!!loading"
                @click="hirePlan(price)"
              >
                <b-spinner v-if="loading === price.id" small></b-spinner>
                <span v-else>{{ $t('public.planes.sectionFeatures2') }}</span>
              </b-button>
            </b-card>
          </div>
        </div>
      </div>

      <!-- confirm plan update -->
      <b-modal
        centered
        title="Atualização de plano"
        ok-variant="primary"
        cancel-variant="outline-secondary"
        :ok-title="$t('alertDialog.ok')"
        :cancel-title="$t('alertDialog.cancel')"
        v-model="showConfirmModal"
        @ok="contractPlan(selectedPrice, user, reHirePlan)"
        @cancel="showConfirmModal = false"
        hide-header-close
      >
        Tem certeza que deseja trocar de plano? Seus limites serão atualizados e
        na próxima fatura o novo valor será cobrado
      </b-modal>
    </div>
    <div v-else>
      <div
        class="col-12 col-md-10 offset-md-1 top40"
        v-if="$store.state.plan && plans && plans[0] && myProduct"
      >
        <div class="plans-grid">
          <div v-for="price in plans[0].prices" :key="price.id">
            <div v-if="price.active">
              <b-overlay
                :id="price.id"
                :show="isCurrentActivePlan(price) && !isInactive"
              >
                <template #overlay>
                  <div class="text-center">
                    <b-badge variant="success">
                      <span
                        style="
                          font-color: white;
                          font-weight: bold;
                          font-size: 20px;
                        "
                        >Plano Atual</span
                      >
                    </b-badge>
                  </div>
                </template>
                <b-card align="center" class="mb-1" style="width: 300px">
                  <b-card-title bold class="card-title m-0">{{
                    price.metadata.pricingName
                  }}</b-card-title>

                  <h2 class="plan-price top20 text-secondary">
                    <span class="unit">{{ getCurrency(price.currency) }}</span
                    >{{ formatPrice(price.unit_amount)
                    }}<span class="month">{{
                      $t('public.planes.sectionFeatures1')
                    }}</span>
                  </h2>

                  <b-list-group flush class="list-group-circle top20">
                    <b-list-group-item class="text-left">
                      {{ price.metadata.allowedMeasurements }}
                      {{ $t('admin.plansCard.measurements') }}
                    </b-list-group-item>

                    <b-list-group-item class="text-left">
                      {{ price.metadata.allowedSellers }}
                      {{ $t('admin.plansCard.sellers') }}
                    </b-list-group-item>

                    <b-list-group-item class="text-left">
                      {{ $t('admin.plansCard.bonus') }}
                    </b-list-group-item>

                    <b-list-group-item
                      class="text-left"
                      v-if="price.metadata.discount != null"
                    >
                      {{ price.metadata.discount
                      }}{{ $t('admin.plansCard.discount') }}
                    </b-list-group-item>

                    <!-- <b-list-group-item class="text-left">
                  Descrição dos benefícios
                </b-list-group-item> -->
                  </b-list-group>

                  <b-button
                    class="top20"
                    :disabled="!!loading"
                    @click="checkPlanUpdate(price)"
                  >
                    <b-spinner v-if="loading === price.id" small></b-spinner>
                    <span v-else>{{
                      $t('public.planes.sectionFeatures2')
                    }}</span>
                  </b-button>
                </b-card>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>

      <b-row align-h="center" style="margin-top: 100px" v-if="!isInactive">
        <b-button variant="danger" @click="cancelSubscriptionModal = true">
          <feather-icon icon="XIcon" class="mr-50" />
          Cancelar Plano</b-button
        >
      </b-row>

      <!-- confirm plan update -->
      <b-modal
        centered
        title="Atualização de plano"
        ok-variant="primary"
        cancel-variant="outline-secondary"
        :ok-title="$t('alertDialog.ok')"
        :cancel-title="$t('alertDialog.cancel')"
        v-model="showConfirmModal"
        @ok="contractPlan(selectedPrice, user)"
        @cancel="showConfirmModal = false"
        hide-header-close
      >
        Tem certeza que deseja trocar de plano? Seus limites serão atualizados e
        na próxima fatura o novo valor será cobrado
      </b-modal>

      <AlertDialog
        id="warning-modal"
        :show="warningModal"
        :message="$t('admin.measurements.list.ErrorDialog.message')"
        :title="$t('admin.measurements.list.ErrorDialog.title')"
        :warnOnly="true"
        @close="warningModal = false"
        :centered="true"
      />

      <AlertDialog
        id="warning-modal"
        :show="cancelSubscriptionModal"
        :message="$t('admin.measurements.list.CancelDialog.message')"
        :title="$t('admin.measurements.list.CancelDialog.title')"
        @close="cancelSubscriptionModal = false"
        @action="cancelSubscription"
        :centered="true"
      />
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardTitle,
  BCardText,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BOverlay,
  BBadge,
  BModal,
  BRow,
} from 'bootstrap-vue'

import { onUnmounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import i18n from '@/libs/i18n'

import store from '@/store'
import planStoreModule from '@/views/admin/plans/planStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RoleEnum from '@/utils/roles'
import softwarePartnerStoreModule from '@/views/sections/software-partners/softwarePartnerStoreModule'
import labStoreModule from '@/views/sections/labs/labStoreModule'
import opticStoreModule from '@/views/sections/optics/opticStoreModule'

import StripeApi from '@/api/stripe'
import userStoreModule from '@/views/admin/users/userStoreModule'
import Controller from '@/controllers/base.controller'
import router from '@/router'
import AlertDialog from '@/components/AlertDialog.vue'
import SubscriptionController from '@/controllers/subscriptions.controller'
import creditStoreModule from '../views/sections/credits/creditStoreModule'
import { CreditEntity } from '../entities/credit.entity'
import ReasonEnum from '../utils/credits'
import CheckoutSubscriptionSession from '@/entities/checkout-session-subscription.entity'

export default {
  components: {
    BButton,
    BCard,
    BCardTitle,
    BCardText,
    BListGroup,
    BListGroupItem,
    BSpinner,
    BOverlay,
    BBadge,
    BModal,
    BRow,
    AlertDialog,
  },
  data() {
    return {
      plans: [],
      myProduct: null,
      mainProduct: null,
      showConfirmModal: false,
      selectedPrice: null,
      warningModal: false,
      mySubscription: null,
      cancelSubscriptionModal: false,
      reHirePlan: false,
      load: true,
    }
  },
  async created() {
    await store.dispatch('plan/fetchPlans')

    const typePlans = store.state.plan.plans.filter(
      (p) => p.metadata && p.metadata.type === this.planType,
    )

    typePlans[0].prices.sort((p1, p2) => p1.unit_amount - p2.unit_amount)

    this.plans = typePlans
    this.load = false
  },
  methods: {
    async cancelSubscription() {
      this.load = true
      await new SubscriptionController().cancelSubscription({
        id: this.mySubscription.id,
        user: this.user.id,
      })
      this.$router.push({ name: 'profile' })
      this.load = false
    },
    isCurrentActivePlan(price) {
      return price.description === this.myProduct.description
    },
    hirePlan(price) {
      this.selectedPrice = price
      this.showConfirmModal = true
    },
    checkPlanUpdate(price) {
      // Check if it is downgrade or update
      let isDowngrade
      if (
        price.metadata.allowedMeasurements <
        this.myProduct.metadata.allowedMeasurements
      )
        isDowngrade = true

      // In case of downgrade, check if user has enough measurements and sellers to downgrade
      if (isDowngrade) {
        if (
          this.userDoc.availableMeasurements <
          price.metadata.allowedMeasurements
        ) {
          this.warningModal = true
          return
        } else if (this.userDoc.activeSellers > price.metadata.allowedSellers) {
          this.warningModal = true
          return
        }
      }

      this.selectedPrice = price
      this.showConfirmModal = true
    },
    async getUserCurrentPlan() {
      const subscriptionSnap = await new Controller().getAll([
        'customers',
        this.user.id,
        'subscriptions',
      ])
      if (!subscriptionSnap.size) {
        if (!this.user.plan.id) {
          this.reHirePlan = true
        }
      } else {
        const mySubscription = subscriptionSnap.docs.pop().data()
        this.mySubscription = Object.assign(
          { id: subscriptionSnap.docs[0].id },
          mySubscription,
        )
        const mainProduct = await new Controller().getWithRef(
          mySubscription.product,
        )
        const myProduct = await new Controller().getWithRef(
          mySubscription.prices[0],
        )
        this.myProduct = myProduct.data()
        this.mainProduct = mainProduct.data()
      }
    },
  },
  computed: {
    isInactive() {
      return this.$store.getters['auth/inactive']
    },
    userDoc() {
      return this.$store.state.auth.userAdminDoc
    },
    user() {
      return this.$store.state.auth.user
    },
    planType() {
      switch (this.user.role) {
        case RoleEnum.SOFTWARE_ADMIN:
          return 'software-partner'
        case RoleEnum.LABORATORY_ADMIN:
          return 'laboratory'
        case RoleEnum.OPTIC_ADMIN:
          return 'optic'
        default:
          return ''
      }
    },
  },
  async mounted() {
    await this.getUserCurrentPlan()
  },
  setup() {
    const PLAN_APP_STORE_MODULE_NAME = 'plan'
    const USER_APP_STORE_MODULE_NAME = 'user'
    const SOFTWARE_PARTNER_APP_STORE_MODULE_NAME = 'software-partner'
    const LAB_APP_STORE_MODULE_NAME = 'laboratory'
    const OPTIC_APP_STORE_MODULE_NAME = 'optic'
    const CREDIT_APP_STORE_MODULE_NAME = 'credit'

    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) {
      store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    }

    if (!store.hasModule(CREDIT_APP_STORE_MODULE_NAME)) {
      store.registerModule(CREDIT_APP_STORE_MODULE_NAME, creditStoreModule)
    }

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }
    if (!store.hasModule(SOFTWARE_PARTNER_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        SOFTWARE_PARTNER_APP_STORE_MODULE_NAME,
        softwarePartnerStoreModule,
      )
    }

    if (!store.hasModule(LAB_APP_STORE_MODULE_NAME)) {
      store.registerModule(LAB_APP_STORE_MODULE_NAME, labStoreModule)
    }

    if (!store.hasModule(OPTIC_APP_STORE_MODULE_NAME)) {
      store.registerModule(OPTIC_APP_STORE_MODULE_NAME, opticStoreModule)
    }

    onUnmounted(() => {
      store.hasModule(PLAN_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(PLAN_APP_STORE_MODULE_NAME)

      store.hasModule(CREDIT_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(CREDIT_APP_STORE_MODULE_NAME)

      store.hasModule(USER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)

      store.hasModule(SOFTWARE_PARTNER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SOFTWARE_PARTNER_APP_STORE_MODULE_NAME)

      store.hasModule(LAB_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(LAB_APP_STORE_MODULE_NAME)

      store.hasModule(OPTIC_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(OPTIC_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    store.dispatch('plan/fetchPlans')

    const loading = ref('')

    const formatPrice = (price) => {
      const result = price / 100
      const isDecimal = result.toString().includes('.')

      return (isDecimal ? result.toFixed(2) : result.toString()).replace(
        '.',
        ',',
      )
    }

    const getCurrency = (currency) => {
      switch (currency) {
        case 'brl':
          return 'R$'
        case 'usd':
          return '$'
        case 'eur':
          return '€'
        default:
          return '$'
      }
    }

    const contractPlan = async (price, user, reHirePlan) => {
      if (reHirePlan || user.status === 'canceled-subscription') {
        const priceId = price.id
        loading.value = priceId
        const entityDocData = store.state.auth.userAdminDoc
        const customer = await store.dispatch('user/fetchOneCustomer', user.id)

        const sessionResult =
          await new SubscriptionController().subscribeToProduct(
            new CheckoutSubscriptionSession({
              customer: customer.stripeId,
              lineItems: [{ price: priceId, quantity: 1 }],
              successUrl: `${window.location.origin}/admin`,
              cancelUrl: `${window.location.origin}/admin`,
              subscriptionData: {
                trial_period_days: 7,
              },
            }),
          )
        window.location.href = sessionResult.data.data.url
      } else {
        try {
          const priceId = price.id
          loading.value = priceId

          const stripe = new StripeApi()

          const user = store.state.auth.user
          const ownerRef = user.adminDocRef || user.sellerDocRef || user.id
          const now = new Date()

          const credit = new CreditEntity({
            createdAt: now.toISOString(),
            updatedAt: now.toISOString(),
            date: now.getTime(),
            ownerRef,
            reason: ReasonEnum.RENOVATION,
          })

          const subscriptionSnap = await new Controller().getAll([
            'customers',
            store.state.auth.userId,
            'subscriptions',
          ])
          if (!subscriptionSnap.size) {
            throw new Error('no-customer-subscription')
          }

          const subscription = subscriptionSnap.docs.pop().id

          let myProduct = await new Controller().getWithRef(
            subscriptionSnap.docs.pop().data().prices[0],
          )
          myProduct = myProduct.data()

          await stripe.updateSubscription({
            subscription,
            price: priceId,
          })

          const entityDocData = store.state.auth.userAdminDoc
          const currentAvailableMeasurements = parseInt(
            entityDocData.availableMeasurements,
          )
          const qtyMeasurements = entityDocData.qtyMeasurements

          const newAllowed = parseInt(price.metadata.allowedMeasurements || 0)

          let newAvailable = 0

          const allowedDiff =
            newAllowed - myProduct.metadata.allowedMeasurements

          newAvailable = currentAvailableMeasurements + allowedDiff

          entityDocData.allowedMeasurements = newAllowed
          entityDocData.availableMeasurements = newAvailable

          credit.difference = allowedDiff
          credit.remaining = newAvailable

          let dispatchPath = ''

          switch (store.state.auth.user.role) {
            case RoleEnum.SOFTWARE_ADMIN:
              dispatchPath = 'software-partner/setSoftwarePartner'
              break
            case RoleEnum.LABORATORY_ADMIN:
              dispatchPath = 'laboratory/setLaboratory'
              break
            case RoleEnum.OPTIC_ADMIN:
              dispatchPath = 'optic/setOptic'
              break
          }

          await store.dispatch(dispatchPath, {
            id: entityDocData.id,
            data: entityDocData,
            parent: store.state.auth.user.adminDocRef,
          })

          store.dispatch('credit/create', credit)

          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('toast.upgradePlan.success'),
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          router.push({ name: 'home' })
        } catch (e) {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('toast.upgradePlan.failure'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          loading.value = 'done'
        }
      }
    }

    return {
      formatPrice,
      getCurrency,
      contractPlan,
      loading,
    }
  },
}
</script>

<style scoped>
.card-body {
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-content: space-around;
  flex-wrap: wrap;
}
.card-title {
  font-weight: bold !important;
  font-size: 30px !important;
}

.plans-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  justify-content: center;
}

h2.plan-price {
  font-size: 5rem !important;
}

h2.plan-price span {
  font-size: 2rem !important;
  line-height: 2rem !important;
}

h2.plan-price span.unit {
  vertical-align: super !important;
}

h2.plan-price span.month {
  font-weight: normal !important;
  color: #999;
}

.dark-layout h2.plan-price span.month {
  color: #aaa;
}

h2 {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 55px;
  line-height: 55px;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
</style>
