import { CreditEntity } from '../entities/credit.entity'

import Controller from './base.controller'

const collection = 'credit-history'

/**
 * Class that represents the credit controller.
 */
export class CreditController extends Controller {
  /**
   * Gets all credits from the credit history collection.
   *
   * @param {QueryConstraint[]} constraints the query filters.
   * @returns a list of credits.
   */
  async getAll(constraints) {
    const result = await super.getAll([collection], constraints)

    return result.docs.map((doc) =>
      CreditEntity.fromFirestore({ ...doc.data(), id: doc.id }),
    )
  }

  /**
   * Creates a credit according to the given data.
   *
   * @param {CreditEntity} data the credit to be created.
   * @return the credit entity with its doc id.
   */
  async create(data) {
    const result = await super.create([collection], data.toFirestore())

    return new CreditEntity({ ...data, id: result.id })
  }
}
